:root {
  --icon-fontSize-sm: 1rem;
  --icon-fontSize-md: 1.25rem;
  --icon-fontSize-lg: 1.5rem;

  --maxWidth-xs: 0px;
  --maxWidth-sm: 576px;
  --maxWidth-md: 768px;
  --maxWidth-lg: 1024px;
  --maxWidth-xl: 1280px;

  /* Authentication */
  --AuthHeader-height: 64px;

  /* Maintenance */

  --Maintenance-background: var(--mui-palette-neutral-950);
  --Maintenance-zIndex-xs: 1199;
  --Maintenance-zIndex-md: 1400;

  /* Navigation */
  --Navigation-background: var(--mui-palette-background-paper);
  --Navigation-border: 1px solid var(--Navigation-divider);
  --Navigation-divider: var(--mui-palette-divider);
  --Navigation-color: var(--mui-palette-text-primary);
  --Navigation-zIndex: 1200;
  --Navigation-height: 64px;

  /* Account Menu */
  --Navigation-account-width: 224px;
  --Navigation-account-color: var(--mui-palette-neutral-500);
  --Navigation-account-icon-color: var(--mui-palette-neutral-400);

  --Navigation-account-hover-color: var(--mui-palette-text-primary);
  --Navigation-account-hover-icon-color: var(--mui-palette-primary-main);

  --Navigation-account-active-color: var(--mui-palette-text-primary);
  --Navigation-account-active-icon-color: var(--mui-palette-primary-main);

  /* Sidebar */
  --Sidebar-background: var(--mui-palette-background-paper);
  --Sidebar-color: var(--mui-palette-neutral-500);
  --Sidebar-divider: var(--mui-palette-divider);
  --Sidebar-border: 1px solid var(--Sidebar-divider);
  --Sidebar-zIndex: 1100;
  --Sidebar-width: 280px;

  --Sidebar-balance-background: rgba(var(--mui-palette-success-mainChannel) / 0.16);

  /* Sidebar item */
  --NavGroup-title-color: var(--mui-palette-text-primary);

  --NavItem-color: var(--mui-palette-neutral-500);
  --NavItem-icon-color: var(--mui-palette-neutral-400);

  --NavItem-hover-color: var(--mui-palette-text-primary);
  --NavItem-hover-icon-color: var(--mui-palette-primary-main);

  --NavItem-active-color: var(--mui-palette-text-primary);
  --NavItem-active-icon-color: var(--mui-palette-primary-main);

  /* Content */
  --Content-margin: 0 auto;
  --Content-maxWidth: var(--maxWidth-xl);
  --Content-width: 100%;

  /* Order flow */
  --OrderNavigation-background: var(--mui-palette-background-paper);
  --OrderNavigation-height: 64px;
  --OrderNavigation-zIndex: 1100;
  --OrderNavigation-logo-zIndex: 1102;
  --OrderNavigation-tabs-zIndex: 1101;
  --OrderNavigation-close-zIndex: 1102;

  --OrderSidebar-width: 400px;
  --OrderSidebar-zIndex: 1099;

  --OrderSidebar2-width: 360px;
  --OrderSidebar2-zIndex: 1099;

  --OrderSummary-zIndex: 1099;

  --OrderContent-margin: 0 auto;
  --OrderContent-maxWidth: 976px;
  --OrderContent-width: 100%;

  /* Feature Request */
  --BaseLayoutNavigation-background: var(--mui-palette-background-paper);
  --BaseLayoutNavigation-height: 64px;
  --BaseLayoutNavigation-zIndex: 1100;
  --BaseLayoutNavigation-logo-zIndex: 1102;
  --BaseLayoutNavigation-close-zIndex: 1102;

  --BaseLayout-margin: 0 auto;
  --BaseLayout-maxWidth: 1016px;
  --BaseLayout-width: 100%;

  /* Swiper */
  --Swiper-header-gap: 16px;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

a {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--mui-palette-text-primary);
  box-shadow: none;
}
